import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';

import styles from "../styles/list.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { OrderTypes, UserTypes } from "../../../interfaces";
import { Layout, Pagination } from "../../../ui";
import { list, partnerWebsitesList } from "../api";
import Select from "react-select";
import { toast } from "react-toastify";

const Orders = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [search, setSearch] = useState<string>("");
  const [partenList, setPartenList] = useState<any>([]);
  const [partenListVal, setPartenListVal] = useState<any>('');
  const [partnerWebsiteId, setPartnerWebsiteId] = useState<any>('');
  const [order, setSortOrder] = useState<number>();
  const [sortBy, setSortColumn] = useState<string>("");
  const [payments, setOrdersList] = useState<{
    list: OrderTypes.Payment[];
    count: number;
  }>({
    list: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);

  const [activeTab, setActiveTab] = useState<Number>(1);

  const listOrders = () => {
    const query: any = {
      page: (1 + pagination.skip),
      limit: pagination.limit,
      search,
      order,
      sortBy
    };
    if (partnerWebsiteId) {
      query.partnerWebsiteId = partnerWebsiteId
    }
    list(query).then(({ data }) => {
      setOrdersList({ list: data?.orders, count: data?.count });
      setLoading(false);
    });
  };

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      if (search) listOrders();
    }, 500);

    partnerWebsitesList().then((res) => {
      setPartenList(res?.data)
    }).catch((error) => {
      toast.error(error?.data?.message || "Something went wrong!")
    })

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [search]);
  const partenListOptions = partenList.map((parten: any) => ({
    value: parten._id,
    label: parten?.websiteUrl
  }));

  useEffect(() => {
    setLoading(true);
    listOrders();
  }, [pagination.skip, partnerWebsiteId]);

  const handleSort = (column: string) => {
    setSortColumn(column);
    setSortOrder(
      order === LOCAL_CONSTANTS.descKey
        ? LOCAL_CONSTANTS.ascKey
        : LOCAL_CONSTANTS.descKey
    );
    const query = {
      ...pagination,
      search,
      order:
        order === LOCAL_CONSTANTS.descKey
          ? LOCAL_CONSTANTS.ascKey
          : LOCAL_CONSTANTS.descKey,
      sortBy: column,
    };
    list(query).then(({ data }) => {
      setOrdersList({ list: data?.orders, count: data?.count });
      setLoading(false);
    });
  };
  const renderSortIcon = (column: string) => {
    if (sortBy === column) {
      return order === LOCAL_CONSTANTS.descKey ? " ▼" : " ▲";
    }
    return null;
  };


  return (
    <Layout title="Orders">
      <div className={commonStyles.tableData}>
        <div className={styles.mainTabbing}>
          <div className="leftTabbing">
            <div className={styles.topCms}>
              <ul>

                <li
                  className={activeTab === 1 ? styles.active : ""}
                  onClick={() => {
                    setSearch("")
                    setActiveTab(1)
                  }}
                >
                  <p>Online Orders</p>
                </li>
                <li
                  className={activeTab === 2 ? styles.active : ""}
                  onClick={() => {
                    setSearch("")
                    setActiveTab(2);
                  }}
                >
                  <p>Manual Orders</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={commonStyles.topFilter}>
          {activeTab == 1 && (
            <>
              <div className="w-25">
                <Select
                  options={[{ value: '', label: 'Select option' }, ...partenListOptions]}
                  onChange={(data: any) => {
                    setPartnerWebsiteId(data?.value)
                  }}
                  value={partenListOptions.find((option: any) => option.value === partenListVal)}
                />
              </div>
              <button onClick={() => navigate(APP_ROUTES.LOYALITY_POINTS_SETTINGS)} className={styles.loyalityPointBtn} >Edit Loyality Point</button>

            </>
          )}
          {activeTab == 2 && (
              <button onClick={() => navigate(APP_ROUTES.INDIVIDUAL_ADMIN)} className={styles.loyalityPointBtn} >Add Individual Order</button>
          )}
        </div>
        <div className={styles.scrollTable}>
          <table>
            <thead>
              <tr>
                {LOCAL_CONSTANTS.paymentsTableHeaders?.map(
                  (header: UserTypes.TableHeader) => (
                    <th
                      className={`${["user.fullName", "bundle.name", "price"].includes(
                        header?.value
                      )
                        ? "cursor-pointer"
                        : ""
                        }`}
                      onClick={() => {
                        if (
                          ["user.fullName", "bundle.name", "price"].includes(
                            header?.value
                          )
                        ) {
                          handleSort(header?.value);
                        }
                      }}
                      key={header?.value}
                    >
                      {header?.label}
                      {sortBy === header?.value &&
                        ["user.fullName", "bundle.name", "price"].includes(
                          header?.value
                        ) &&
                        renderSortIcon(header?.value)}
                    </th>

                  )
                )}
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={8} align="center">
                    {LOCAL_CONSTANTS.loading}
                  </td>
                </tr>
              )}
              {!loading && payments.list.length === 0 && (
                <tr>
                  <td colSpan={8} align="center">
                    No Order Found
                  </td>
                </tr>
              )}
              {!loading &&
                payments?.list?.map((item: any) => (
                  <tr key={item._id}>
                    <td>{`${item.user?.fullName || LOCAL_CONSTANTS.na} ${item?.partnerWebsiteName ? "( from " + (item?.partnerWebsiteName).split('.')[0] + " )" : ''}`}</td>
                    <td>{`${item.user?.email || LOCAL_CONSTANTS.na}`}</td>
                    <td>{item.bundle?.name}</td>
                    <td>
                      {moment(item?.createdAt).format(LOCAL_CONSTANTS.dateFormat)}
                    </td>
                    <td>
                      {
                        LOCAL_CONSTANTS.paymentOrderStatus?.find(
                          (elt: OrderTypes.StatusEnums) => elt.value === item.orderStatus
                        )?.label
                      }
                    </td>
                    <td>
                      {
                        LOCAL_CONSTANTS.paymentOrderStatus?.find(
                          (elt: OrderTypes.StatusEnums) => elt.value === item.paymentStatus
                        )?.label
                      }
                    </td>
                    <td>{item.priceCurrency}</td>
                    <td>{(item.price).toFixed(2)}</td>
                    <td>{item.quantity}</td>
                    {/* {item?.documents &&  */}
                    <td><button className={styles.loyalityPointBtn} onClick={() => navigate(`/document/${item._id}`)}>View Documents</button></td>
                    {/* } */}

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={payments.count}
        />
      </div>
    </Layout>
  );
};

export default Orders;
