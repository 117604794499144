import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "../styles/viewPlans.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import {  LOCAL_CONSTANTS } from "../../../lib/constants";
import { getGeoRegions, putGeoRegions, updatePlan } from "../api";
import { Layout, Pagination } from "../../../ui";
import { Toggle } from "../../../components";

const GeofencedRegions = () => {
  const { geoName } = useParams();

  const [getDataList, setDetDataList] = useState<any>({ list: [], count: 0 });
  const [geoUpdate, setGeoUpdate] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const [pagination, setPagination] = useState({
    skip: 1,
    limit: LOCAL_CONSTANTS.limitCount,
  });

  useEffect(() => {
    getGeoRegions({ page: pagination?.skip, limit: pagination?.limit })
      .then((res: any) => {
        setDetDataList({ list: res?.data?.regions, count: res?.data?.count });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [pagination, geoUpdate]);
console.log(getDataList, "  dfsdaf asdg sdag sdg sadg sadg asdg")
  return (
    <Layout title="Regions">
      <div className={commonStyles.tableData}>
        <table>
          <thead>
            <tr>
              <th className={"cursor-pointer"}>Flag</th>
              <th className={"cursor-pointer"}>Country Name</th>
              <th className={"cursor-pointer"}>Country Code ( ISO )</th>
              <th className={"cursor-pointer"}>geofence</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={12} align="center" className={styles.loadingText}>
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading &&
              getDataList.list.map((item: any) => (
                <tr key={item._id}>
                  <td>
                    <img
                      src={item?.flagImageUrl}
                      alt=""
                      style={{ width: "30px" }}
                    />
                  </td>
                  <td>{item?.name}</td>
                  <td>{item?.iso}</td>
                  <td><Toggle handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const isChecked = e.target.checked;
                        putGeoRegions({  countryId:item._id, geofence: geoName }).then((res) => {
                          setGeoUpdate(!geoUpdate)
                          toast.success("geofenced successfully update")
                        })
                        .catch((error) => {
                          console.error(error, "Failed to update geo plan");
                        });
                      }} value={item?.geofence?.includes(geoName)} /></td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={getDataList.count}
        />
      </div>
    </Layout>
  );
};

export default GeofencedRegions;
