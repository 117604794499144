import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { faEye, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "../styles/countryRegions.module.css";
import { getCountryRegionsPlanDetails, tabList, listCountries, listRegions } from "../api";
import { ActionButton, DropDownMenu, Layout } from "../../../ui";
import { PromoTypes, PlanTypes } from "../../../interfaces";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import PlanDetailsSettings from "../../../ui/Popup/planDetailSettings";

const CountryRegionsList = () => {
  const navigate = useNavigate();
  const  params =useParams()
  const location = useLocation();

  const getURLPath = location?.pathname?.split('/')
  const getLast = getURLPath[getURLPath?.length - 1]
  const getPathName = (LOCAL_CONSTANTS?.LASTPATHNAME?.SANDBOX_PLANS === getLast)

  const [homeTabs, setHomeTabs] = useState<PlanTypes.HomeTab[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [countries, setCountries] = useState<PromoTypes.Country[]>([]);
  const [regions, setRegions] = useState<PromoTypes.Region[]>([]);
  const [planDetails, setPlanDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const [modal, setModal] = useState<{ show: boolean, iso: string }>({ show: false, iso: "" });
  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      tabList().then(res => {
        setHomeTabs(res.data);
        setActiveTab(1);
      });

      listCountries({ limit: 1000 }).then(res => {
        setCountries(res.data.countries.filter((item: any) => item.flagImageUrl && item.historicImageUrl));
        setLoading(false);
      });

      listRegions().then(res => {
        setRegions(res.data.regions);
      });
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, []);

  const listPlanDetails = (id: string, iso: string) => {
    let query: any = {};

    if (activeTab === 1) {
      query["countryId"] = id;
    }
    else query["regionId"] = id;

    getCountryRegionsPlanDetails(query).then(res => {
      setPlanDetails({ ...res.data, ...query });
      setModal({ show: true, iso: iso });
    });
  };

  if (loading) {
    return <Layout title="Plans"><div className={styles.loading}>Loading</div></Layout>
  }

  return (
    <Layout title={getPathName ? "SandBox Plans" : params?.partnerName ? `${params?.partnerName.charAt(0).toUpperCase()+params?.partnerName.slice(1)} Plans` : "Plans"}>
      <div className={styles.topFilter}>
        <div className={`${styles.filterLeft} w-100`}>
          <div className="d-flex justify-content-between">
            <div>
              {homeTabs.map(item => (
                <button key={item._id} className={item.type === activeTab ? styles.active : ""} onClick={() => setActiveTab(item.type)}>{item.name}</button>
              ))}
            </div>
            <button onClick={() => navigate(`${APP_ROUTES.PLANS_SETTINGS}`)}>Settings</button>
          </div>
        </div>
      </div>

      <div className={styles.PlansList}>
        <div className="row">
          {activeTab === 1 && countries.map((item: any) => (
            <div key={item._id} className="col-md-2 cursor-pointer">
              {/* */}
              <div className={styles.planslIstinner}>
                <div className="d-flex justify-content-between align-items-center">
                  <img className={styles.flgcnt} height={100} width={100} src={item.flagImageUrl} alt="logo" />
                  <Dropdown className={styles.zIndex}>
                    <div className="cursor-pointer">
                      <Dropdown.Toggle as={ActionButton}></Dropdown.Toggle>
                    </div>
                    <Dropdown.Menu as={DropDownMenu}>
                      <Dropdown.Item
                        onClick={(event: any) => {
                          event.stopPropagation();
                          let query: any = {
                            _id: item?._id,
                            documentsRequired: item?.documentsRequired,
                            popularCountries: item?.popularCountries
                          };
                          setPlanDetails(query);
                          setModal({ show: true, iso: "iso" });
                        }}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          className="me-2"
                          icon={faPencilAlt}
                        />
                        Update
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => getPathName ? navigate(`/sandbox-plans/countries/${item?._id}?country=${item?.name}`) :  params?.partnerName ? navigate(`/partners/${params?.partnerName}/${params?.partnerWebsiteId}/plans/countries/${item?._id}?country=${item?.name}`) : navigate(`/plans/countries/${item?._id}?country=${item?.name}`)}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          className="me-2"
                          icon={faEye}
                        />
                        View
                      </Dropdown.Item>
                      {!getPathName &&
                        <Dropdown.Item
                          onClick={() => navigate(`/seo/${item._id}?country=${item?.name}`)}
                        >
                          <FontAwesomeIcon
                            size="xs"
                            className="me-2"
                            icon={faEye}
                          />
                          SEO
                        </Dropdown.Item>
                      }

                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <h5>{item.name}</h5>
              </div>
            </div>
          ))}

          {activeTab === 2 && regions.map((item: any) => (
            <div key={item._id} className="col-md-2 cursor-pointer">
              <div className={styles.planslIstinner}>

                <div className="d-flex justify-content-between align-items-center">
                  <img className={styles.flgcnt} height={100} width={100} src={item.flagImageUrl} alt="logo" />
                  <Dropdown className={styles.zIndex}>
                    <div className="cursor-pointer">
                      <Dropdown.Toggle as={ActionButton}></Dropdown.Toggle>
                    </div>
                    <Dropdown.Menu as={DropDownMenu}>
                      <Dropdown.Item
                        onClick={(event: any) => {
                          event.stopPropagation();
                          let query: any = {
                            _id: item?._id,
                            documentsRequired: item?.documentsRequired,
                            popularCountries: item?.popularCountries
                          };
                          setPlanDetails(query);
                          setModal({ show: true, iso: "iso" });
                        }}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          className="me-2"
                          icon={faPencilAlt}
                        />
                        Update
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(`/plans/regions/${item._id}`)}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          className="me-2"
                          icon={faEye}
                        />
                        View
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <h5>{item.name}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>

      <PlanDetailsSettings
        show={modal.show}
        handleClose={() => setModal({ show: false, iso: "" })}
        planDetails={planDetails}
        iso={modal.iso}
      />
    </Layout>
  );
};

export default CountryRegionsList;