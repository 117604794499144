import axios from "axios";
import { sandboxAxios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";
import { SANDBOX_API_URL } from "../../../config";


export const sandboxLogin = (payload: any): Promise<any> => {
	return sandboxAxios.post(API_ROUTES.AUTH.LOGIN, payload);
};

export const sandboxGenerateApiSecretKey = (sanboxTokan:string): Promise<any> => {
	return axios.get(`${SANDBOX_API_URL}/collaborators/generateApiKey`, {
        headers: {
          Authorization: `Bearer ${sanboxTokan}`, // Set the Bearer Token here
        },
      });
};

export const getSandboxoApiSecretKey = (sanboxTokan:string): Promise<any> => {
	return axios.get(`${SANDBOX_API_URL}/collaborators/getClientCredentials`, {
        headers: {
            Authorization: `Bearer ${sanboxTokan}`, // Set the Bearer Token here
          },
    });
};