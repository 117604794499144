export const CONSTANTS = {
	VALIDATIONS: {
		REQUIRED: "Required",
		EMAIL: "Enter Valid Email"
	},
	AUTH: {
		LOGGING: "Logging In",
		LOGIN: "Successfully Logged In",
		NOTLOGIN: "Invalid credentials"
	},
	CMS: {
		UPDATING: "Updating CMS",
		SUCCESS: "Successfully updated CMS",
		FAILED: "Failed to update CMS",
	},
	PROFILE: {
		UPDATING: "Update Profile",
		SUCCESS: "Profile updated successfully",
		FAILED: "Failed to update profile",
		PASSWORD: {
			SUCCESS: "Password updated successfully"
		}
	},
	PROMO: {
		UPDATING: "Updating promo",
		CREATING: "Creating new promo",
		CREATE_SUCCESS: "New promo created successfully",
		UPDATE_SUCCESS: "Promo updated successfully",
		UPDATE_FAILED: "Failed to update promo",
		REMOVE_FAILED: "Failed to remove promo",
		REMOVE_SUCCESS: "Promo removed successfully",
		CREATE_FAILED: "Failed to create promo",
		REMOVING: "Removing promo"
	},
	COUPON: {
		UPDATING: "Updating coupon",
		CREATING: "Creating new coupon",
		CREATE_SUCCESS: "New coupon created successfully",
		UPDATE_SUCCESS: "Coupon updated successfully",
		UPDATE_FAILED: "Failed to update coupon",
		REMOVE_FAILED: "Failed to remove coupon",
		REMOVE_SUCCESS: "Coupon removed successfully",
		CREATE_FAILED: "Failed to create coupon",
		REMOVING: "Removing coupon"
	},
	LOYALITY_POINT: {
		UPDATING: "Updating loyality point",
		UPDATE_SUCCESS: "Loyality point updated successfully",
		UPDATE_FAILED: "Failed to update loyality point",
	},
	BLOGS: {
		CREATING: "Creating new blog",
		CREATE_SUCCESS: "New blog created successfully",
		CREATE_FAILED: "Failed to create blog",
		UPDATING: "Updating blog",
		UPDATE_SUCCESS: "Blog updated successfully",
		UPDATE_FAILED: "Failed to update blog",
		REMOVING: "Removing blog",
		REMOVE_FAILED: "Failed to remove blog",
		REMOVE_SUCCESS: "Blog removed successfully",
		UPDATE_STATUS_SUCCESS: "Blog status updated successfully",
		UPDATING_STATUS: "Updating blog status",
		UPDATE_STATUS_FAILED: "Failed to update blog status",
	},
	FAQ: {
		CREATING: "Creating new faq",
		CREATE_SUCCESS: "New faq created successfully",
		CREATE_FAILED: "Failed to create faq",
		UPDATING: "Updating faq",
		UPDATE_SUCCESS: "FAQ updated successfully",
		UPDATE_FAILED: "Failed to update faq",
		REMOVING: "Removing faq",
		REMOVE_FAILED: "Failed to remove faq",
		REMOVE_SUCCESS: "FAQ removed successfully",
	},
	BLOG_COMMENT: {
		UPDATING_STATUS: "Updating blog comment status",
		UPDATE_STATUS_SUCCESS: "Blog comment status updated successfully",
		UPDATE_STATUS_FAILED: "Failed to update blog comment status",
	},
	IMAGE_UPLOAD: {
		UPLOADING: "Uploading new image",
		UPLOAD_SUCCESS: "Image uploaded successfully",
		UPLOAD_FAILED: "Image uploaded failed",
	},
	PLANS: {
		UPDATING_STATUS: "Updating plan status",
		UPDATE_SUCCESS: "Plan updated successfully",
		UPDATE_FAILED: "Failed to update plan",
	},
	HOME_TAB: {
		UPDATING_NAME: "Updating tab name",
		UPDATE_NAME_SUCCESS: "Tab name updated successfully",
		UPDATE_NAME_FAILED: "Failed to update tab name",

		UPDATING_DEFAULT: "Updating tab name",
		UPDATE_DEFAULT_SUCCESS: "Tab name updated successfully",
		UPDATE_DEFAULT_FAILED: "Failed to update tab name",
	},
	POPULAR_COUNTRIES: {
		UPDATING: "Updating popular countries",
		UPDATE_POPULAR_COUNTRIES_SUCCESS: "Popular countries updated successfully",
		UPDATE_POPULAR_COUNTRIES_FAILED: "Popular countries failed to update",
	},
	PLAN_DETAILS: {
		UPDATING: "Updating plan details",
		UPDATE_PLAN_DETAILS_SUCCESS: "Plan details updated successfully",
		UPDATE_PLAN_DETAILS_FAILED: "Plan details failed successfully",
	},
	ENABLE_PLAN_WITHOUT_RETAIL_AMOUNT: "You need to add retail amount before enabling the plan",
	NOTIFICATIONS: {
		DELETING: "Deleting notification",
		DELETE_SUCCESS: "Notification deleted successfully",
		DELETE_FAILED: "Failed to delete notification"
	}

}