import { CMSTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";


export const list = (params: CMSTypes.ListCMSQuery): Promise<CMSTypes.ListCMSResponse> => {
  return axios.get(API_ROUTES.CMS.LIST, { params });
}

export const update = (payload: CMSTypes.UpdateCMSPayload): Promise<CMSTypes.ListCMSResponse> => {
  return axios.patch(API_ROUTES.CMS.LIST, payload);
}

export const listFAQs = (query?: CMSTypes.ListFAQQuery): Promise<CMSTypes.FAQListResponse> => {
  return axios.get(API_ROUTES.FAQ.LIST, { params: query });
}

export const createFAQ = (payload: CMSTypes.CreateFAQPayload): Promise<CMSTypes.FAQListResponse> => {
  return axios.post(API_ROUTES.FAQ.LIST, payload);
}

export const updateFAQ = (payload: CMSTypes.UpdateFAQPayload): Promise<CMSTypes.FAQListResponse> => {
  return axios.patch(API_ROUTES.FAQ.LIST, payload);
}

export const removeFAQ = (id: string): Promise<CMSTypes.FAQListResponse> => {
  return axios.delete(`${API_ROUTES.FAQ.LIST}/${id}`);
}

export const listTroubleShoot = (query?: CMSTypes.ListFAQQuery): Promise<CMSTypes.FAQListResponse> => {
  return axios.get(API_ROUTES.TROUBLESHOOT.LIST, { params: query });
}

export const createTroubleShoot= (payload: CMSTypes.CreateFAQPayload): Promise<CMSTypes.FAQListResponse> => {
  return axios.post(API_ROUTES.TROUBLESHOOT.LIST, payload);
}
export const updateTroubleShoot = (payload: CMSTypes.UpdateTroubleShootPayload): Promise<CMSTypes.FAQListResponse> => {
  return axios.patch(API_ROUTES.TROUBLESHOOT.LIST, payload);
}

export const removeTroubleShoot = (id: string): Promise<CMSTypes.FAQListResponse> => {
  return axios.delete(`${API_ROUTES.TROUBLESHOOT.LIST}/${id}`);
}