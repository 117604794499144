import { OrderTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";


export const list = (
  params: OrderTypes.PaymentListQuery
): Promise<OrderTypes.PaymentListResponse> => {
  return axios.get(API_ROUTES.PAYMENT.LIST, { params });
};

export const partnerWebsitesList = (): Promise<any> => {
  return axios.get(`/partner-websites`);
};

export const uploadImage = async (values:any) => {
  const headers:any = {
      'Content-Type': 'multipart/form-data',
    };
  return axios.post(`/common/upload`, values, headers);
}

