import Axios, { InternalAxiosRequestConfig } from "axios";

import { API_URL, SANDBOX_API_URL } from "../config";
import storage from "../utils/storage";

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
	const token = storage.getToken();

	config.headers = config.headers || {};

	if (token) {
		config.headers.authorization = `Bearer ${token}`;
	}
	config.headers.Accept = "application/json";
	return config;
}

export const axios = Axios.create({
	baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			localStorage.clear();
			window.location.href = '/';
		}
		return Promise.reject(error.response);
	}
);


export const sandboxAxios = Axios.create({
	baseURL: SANDBOX_API_URL,
});

sandboxAxios.interceptors.request.use(authRequestInterceptor);
sandboxAxios.interceptors.response.use(
	(response) => {
		return response.data;
	},
	// (error) => {
	// 	if (error.response && (error.response.status === 401 || error.response.status === 403)) {
	// 		localStorage.clear();
	// 		window.location.href = '/';
	// 	}
	// 	return Promise.reject(error.response);
	// }
);