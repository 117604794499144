import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';


const ManualOrderSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required!')
    .matches(/^[A-Za-z\s]+$/, 'Firt name contains only alphabetic characters')
    .max(25)
    .test('no-whitespace', 'First name should not start or end with whitespace', value => {
        if (value) {
            return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
    }),
    lastName: Yup.string().required('Last name is required!')
    .matches(/^[A-Za-z\s]+$/, 'Last name contains only alphabetic characters')
    .max(25)
    .test('no-whitespace', 'Last name should not start or end with whitespace', value => {
        if (value) {
            return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
    }),

    bundleName: Yup.string().required('Bundle name is required!')
    .matches(/^[A-Za-z\s]+$/, 'bundle name contains only alphabetic characters')
    .max(25)
    .test('no-whitespace', 'bundle name should not start or end with whitespace', value => {
        if (value) {
            return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
    }),

    phoneNumber:
        Yup
            .string()
            .required("Phone number is required field!")
            .test('phone-number', function (value) {
                if (value) {
                    if (!isPossiblePhoneNumber(value as string) || !isValidPhoneNumber(value as string)) {
                        return this.createError({ message: 'Invalid mobile number', path: this.path });
                    }
                    return true;
                }
                return true;
            }),
    email: Yup.string().required('Email is required!').email(),

    buyPrice: Yup.string().required('Price is required!')
    .matches(/^[0-9\s]+$/, 'price contains only digits')
    .max(5)
    .test('no-whitespace', 'price should not start or end with whitespace', value => {
        if (value) {
            return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
    }),

    quantity: Yup.string().required('Quantity is required!')
    .matches(/^[0-9\s]+$/, 'quantity contains only digits')
    .max(5)
    .test('no-whitespace', 'quantity should not start or end with whitespace', value => {
        if (value) {
            return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
    }),
});

export {
    ManualOrderSchema
};