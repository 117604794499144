import { toast } from "react-toastify";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

import styles from "../styles/viewPlans.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { PlanTypes } from "../../../interfaces";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { getPlans, updatePlan } from "../api";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout, Pagination } from "../../../ui";
import { Toggle } from "../../../components";

const GeofencdPlans = () => {
  const { geoName } = useParams();

  const [getDataList, setDetDataList] = useState<any>({ list: [], count: 0 });
  const [geoUpdate, setGeoUpdate] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });

  useEffect(() => {
    getPlans({ page: pagination?.skip + 1, limit: pagination?.limit })
      .then((res: any) => {
        setDetDataList({ list: res?.data?.bundles, count: res?.data?.count });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [pagination, geoUpdate]);

  return (
    <Layout title="Plans">
      <div className={commonStyles.tableData}>
        <table>
          <thead>
            <tr>
              <th className={"cursor-pointer"}>Plan name</th>
              <th className={"cursor-pointer"}>Validity</th>
              <th className={"cursor-pointer"}>Data</th>
              <th className={"cursor-pointer"}>Network</th>
              <th className={"cursor-pointer"}>Plan Amount</th>
              <th className={"cursor-pointer"}>Active Plan</th>
              <th className={"cursor-pointer"}>Popular Plan</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={12} align="center" className={styles.loadingText}>
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading && getDataList.list.length === 0 && (
              <tr>
                <td colSpan={12} align="center">
                  No Plan Found
                </td>
              </tr>
            )}
            {!loading &&
              getDataList.list.map((item: any) => (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>{item.duration} Days</td>
                  <td>{item.dataAmount / 1000} GB</td>
                  <td>{item.speed.length > 0 ? item.speed.join("/") : "-"}</td>
                  <td>{item.retailPrice}</td>
                  <td>{item?.isActive ? "Active" : "Not Active"}</td>
                  <td><Toggle handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const isChecked = e.target.checked;
                        updatePlan({ geofence: geoName }, item._id)
                          .then((res) => {
                            setGeoUpdate(!geoUpdate)
                            toast.success("geofenced successfully update")
                          })
                          .catch((error) => {
                            console.error(error, "Failed to update geo plan");
                          });
                      }} value={item?.geofence?.includes(geoName)} /></td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={getDataList.count}
        />
      </div>
    </Layout>
  );
};

export default GeofencdPlans;
