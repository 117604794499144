import React, { useEffect, useState } from "react";

import styles from "../styles/cms.module.css";
import { list } from "../api";
import CommonCMS from "../components/Common";
import ContactUs from "../components/ContactUs";
import FAQ from "../components/FAQ";
import { CMS_TYPE } from "../../../lib/constants";
import { CMSTypes } from "../../../interfaces";
import { Layout } from "../../../ui";
import TroubleShooting from "../components/TroubleShooting";

const CMS = () => {
  const [cmsType, setCMSType] = useState<number>(CMS_TYPE.PRIVACY_POLICY);
  const [cms, setCMS] = useState<CMSTypes.SpecificCMS>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    const debounceSearch = setTimeout(() => {
      resetCMS();
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
    
  }, [cmsType]);

  const resetCMS = () => {
    const query = { type: cmsType };

    list(query).then((res) => {
      if (res.data && res.data.fields) {
        let fields: any = {};

        for (const item of res.data.fields) fields[item.name] = item.value;

        setCMS({ ...res.data, contactUsFields: fields });
      }else setCMS({ ...res.data });

      setLoading(false);
    });
  };

  return (
    <Layout title="CMS">
      <div className={styles.cms}>
        <div className={styles.topCms}>
          <ul>
            <li
              className={cmsType === CMS_TYPE.PRIVACY_POLICY ? styles.active : ""}
              onClick={() => setCMSType(CMS_TYPE.PRIVACY_POLICY)}
            >
              <p>Privacy Policy</p>
            </li>
            <li
              className={
                cmsType === CMS_TYPE.TERMS_CONDITIONS ? styles.active : ""
              }
              onClick={() => setCMSType(CMS_TYPE.TERMS_CONDITIONS)}
            >
              <p>Term & Condition</p>
            </li>
            <li
              className={cmsType === CMS_TYPE.ABOUT_US ? styles.active : ""}
              onClick={() => setCMSType(CMS_TYPE.ABOUT_US)}
            >
              <p>About Us</p>
            </li>
            <li
              className={cmsType === CMS_TYPE.CONTACT_US ? styles.active : ""}
              onClick={() => setCMSType(CMS_TYPE.CONTACT_US)}
            >
              <p>Contact Us</p>
            </li>
            <li
              className={cmsType === CMS_TYPE.HOW_TO_USE ? styles.active : ""}
              onClick={() => setCMSType(CMS_TYPE.HOW_TO_USE)}
            >
              <p>How to use</p>
            </li>
            <li
              className={cmsType === CMS_TYPE.FAQ ? styles.active : ""}
              onClick={() => setCMSType(CMS_TYPE.FAQ)}
            >
              <p>FAQ</p>
            </li>

            <li
              className={cmsType === CMS_TYPE.TROUBLESHOOTING ? styles.active : ""}
              onClick={() => setCMSType(CMS_TYPE.TROUBLESHOOTING)}
            >
              <p>TroubleShooting</p>
            </li>

            <li
              className={cmsType === CMS_TYPE.REFUND_POLICY ? styles.active : ""}
              onClick={() => setCMSType(CMS_TYPE.REFUND_POLICY)}
            >
              <p>Refund Policy</p>
            </li>
            <li
              className={cmsType === CMS_TYPE.SHIPPIN_POLICY ? styles.active : ""}
              onClick={() => setCMSType(CMS_TYPE.SHIPPIN_POLICY)}
            >
              <p>Shipping Policy</p>
            </li>
          </ul>
        </div>

        {
          [CMS_TYPE.PRIVACY_POLICY, CMS_TYPE.TERMS_CONDITIONS, CMS_TYPE.ABOUT_US, CMS_TYPE.HOW_TO_USE, CMS_TYPE.REFUND_POLICY, CMS_TYPE.SHIPPIN_POLICY].includes(cmsType) ?
            <CommonCMS cms={cms} cmsType={cmsType} loading={loading} resetCMS={resetCMS} />
            :
            null
        }
        {cmsType === CMS_TYPE.CONTACT_US ? <ContactUs cms={cms} cmsType={cmsType} loading={loading} resetCMS={resetCMS} /> : null}
        {cmsType === CMS_TYPE.FAQ ? <FAQ cms={cms} cmsType={cmsType} resetCMS={resetCMS} /> : null}
        {cmsType === CMS_TYPE.TROUBLESHOOTING ? <TroubleShooting cms={cms} cmsType={cmsType} resetCMS={resetCMS} /> : null}
      </div>
    </Layout>
  );
};

export default CMS;
