import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import styles from "../styles/list.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { CouponTypes } from "../../../interfaces";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout, ActionButton, DropDownMenu, Popup, Pagination } from "../../../ui";
import { getCoupons, removeCoupon } from "../api";


const OffersForYouList = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [search, setSearch] = useState<string>("");
  const [order, setSortOrder] = useState<number>(0);
  const [sortBy, setSortColumn] = useState<string>("");

  const [coupons, setCoupons] = useState<{ list: CouponTypes.Coupon[]; count: number }>({
    list: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [dialog, setDialog] = useState({ show: false, id: "" });

  const listCoupons = () => {

    const query: any = {
      page: (1 + pagination.skip),
      limit: pagination.limit,
      search,
      order,
      sortBy,
    };

    getCoupons(query).then(({ data }) => {
      setCoupons({ list: data.coupons, count: data.count });
      setLoading(false);
    });
  };

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      setLoading(true);
      listCoupons();
    }, 500);
    
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [pagination.skip]);

  const handleSort = (column: string) => {
    setSortColumn(column);
    setSortOrder(
      order === LOCAL_CONSTANTS.descKey
        ? LOCAL_CONSTANTS.ascKey
        : LOCAL_CONSTANTS.descKey
    );

    const query = {
      ...pagination,
      search,
      order:
        order === LOCAL_CONSTANTS.descKey
          ? LOCAL_CONSTANTS.ascKey
          : LOCAL_CONSTANTS.descKey,
      sortBy: column,
    };

    getCoupons(query).then(({ data }) => {
      setCoupons({ list: data.coupons, count: data.count });
      setLoading(false);
    });
  };

  const renderSortIcon = (column: string) => {
    if (sortBy === column) {
      return order === LOCAL_CONSTANTS.descKey ? " ▼" : " ▲";
    }
    return null;
  };

  const deleteCoupon = () => {
    toast.promise(
      removeCoupon(dialog.id),
      {
        pending: {
          render() {
            return CONSTANTS.COUPON.REMOVING;
          },
        },
        success: {
          render() {
            let tempCoupons = coupons.list.filter((item) => item._id !== dialog.id);

            setCoupons({ ...coupons, list: tempCoupons });

            setDialog({ show: false, id: "" });
            return CONSTANTS.COUPON.REMOVE_SUCCESS;
          },
        },
        error: {
          render({ data }: any) {
            return data.data.message;
          },
        },
      });
  };

  return (
    <Layout title="Offers For You">
      <div className={commonStyles.tableData}>
        <div className={commonStyles.topFilter}>
          <input
            type="search"
            placeholder="Search by title or coupon code"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                setLoading(true);
                const debounceSearch = setTimeout(() => {
                  listCoupons();
                }, 500);
                return () => {
                  clearTimeout(debounceSearch);
                };
              };
            }}
          />
          <button onClick={() => navigate(APP_ROUTES.NEW_COUPON)} className={styles.assCoupon} >+ Add new coupon</button>
        </div>
        <div className={styles.scrollTable}>
          <table>
            <thead>
              <tr>
                {LOCAL_CONSTANTS.couponTableHeaders?.map((header: any) => (
                  <th
                    className={`${header?.value
                      ? "cursor-pointer"
                      : ""
                      }`}
                    onClick={() => {
                      if (header?.value) {
                        handleSort(header?.value);
                      }
                    }}
                    key={header?.value}
                  >
                    {header?.label}
                    {sortBy === header?.value &&
                      header?.value &&
                      renderSortIcon(header?.value)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={7} align="center">
                    {LOCAL_CONSTANTS.loading}
                  </td>
                </tr>
              )}
              {!loading && coupons.list.length === 0 && (
                <tr>
                  <td colSpan={7} align="center">
                    {LOCAL_CONSTANTS.noCouponFound}
                  </td>
                </tr>
              )}
              {!loading &&
                coupons.list.map((item) => (
                  <tr key={item._id}>
                    <td>{item.couponCode}</td>
                    <td>{item.title}</td>
                    <td>{item.type === 1 ? "Country Specific" : "Region Specific"}</td>
                    <td>{item.discount} %</td>
                    <td>{moment(item?.startDate).format(LOCAL_CONSTANTS.dateFormat)}</td>
                    <td>{moment(item?.endDate).format(LOCAL_CONSTANTS.dateFormat)}</td>
                    <td>
                      <Dropdown>
                        <div className="cursor-pointer">
                          <Dropdown.Toggle as={ActionButton}></Dropdown.Toggle>
                        </div>
                        <Dropdown.Menu as={DropDownMenu}>
                          <Dropdown.Item onClick={() => navigate(`${APP_ROUTES.COUPONS}/${item._id}`)}>
                            <FontAwesomeIcon
                              size="xs"
                              className="me-2"
                              icon={faPencilAlt}
                            />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setDialog({ show: true, id: item._id })}>
                            <FontAwesomeIcon
                              size="xs"
                              className="me-2"
                              icon={faTrashAlt}
                            />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
        count={coupons.count}
      />
      <Popup
        show={dialog.show}
        handleClose={() => setDialog({ show: false, id: "" })}
        heading={LOCAL_CONSTANTS.areYouSure}
        content={LOCAL_CONSTANTS.removeCoupon}
        handleSubmit={deleteCoupon}
        id={dialog.id}
      />
    </Layout>

  );
};

export default OffersForYouList;
