import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "../styles/viewPlans.module.css";
import commonStyles from '../../../lib/common/common.module.css';
import { PlanTypes } from "../../../interfaces";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout, Pagination } from "../../../ui";
import { Toggle } from "../../../components";
import { activePartnerEsimBundles, getPartnerEsimBundles } from "../api";
import UserList from "./userList";

const PartnerViewPlans = () => {
  
  const params = useParams();
  const location = useLocation();

  const getURLPath = location?.pathname?.split('/')
  const getLast = getURLPath[getURLPath?.length - 1]
  const getPathName = (LOCAL_CONSTANTS?.LASTPATHNAME?.VENDOR_API === getLast)

  const { partnerName, partnerWebsiteId } = useParams();
  // const navigate = useNavigate();

  const [plans, setPlans] = useState<{ list: PlanTypes.Plan[], count: number }>({ list: [], count: 0 });
  const [loading, setLoading] = useState<boolean>(true);

  const [priceToggle, setPriceToggle] = useState<any>("");

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [activeTab, setActiveTab] = useState<number>(1);
  const [search, setSearch] = useState("");

  const listPlans = () => {
    let query: any = {
      page: (1 + pagination.skip),
      limit: pagination.limit,
      vendor: activeTab,
      partnerWebsiteId: partnerWebsiteId,
      userType : 2
    };
    if (search) query.search = search;

    getPartnerEsimBundles(query).then(res => {
      setPlans({ list: res.data.bundles, count: res.data.count });
      setLoading(false);
    });
  };

  const updatePlanData = (plan: any) => {
    let payload: any = {
      isActive: !plan.isActive,
      bundleId: plan._id,
      partnerWebsiteId,
      userType : 2
    };

    toast.promise(
      activePartnerEsimBundles(payload),
      {
        pending: {
          render() {
            return CONSTANTS.PLANS.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            listPlans();
            return CONSTANTS.PLANS.UPDATE_SUCCESS;
          }
        },
        error: {
          render() {
            return CONSTANTS.PLANS.UPDATE_FAILED;
          }
        }
      }
    )
  }

  useEffect(() => {
    listPlans();
  }, [activeTab, pagination, search]);

  const togglePlanRetailPrice = (item:string) => {
    setPriceToggle(item)
  }
  
  return (
    <Layout title={`Plans for ${partnerName}`}>
      <div className={commonStyles.tableData}>
        <div className={commonStyles.mainTabbing}>
          <div className="leftTabbing">
            <div className={commonStyles.topCms}>
              <ul>
                <li className={activeTab === 1 ? commonStyles.active : ""}
                  onClick={() => {
                    setActiveTab(1)
                    setPagination({ ...pagination, skip: 0 })
                    setSearch("")
                  }}><p>G+</p></li>
                <li className={activeTab === 2 ? commonStyles.active : ""}
                  onClick={() => {
                    setActiveTab(2)
                    setPagination({ ...pagination, skip: 0 })
                    setSearch("")
                  }}><p>Commbitz</p></li>
              </ul>
            </div>
            <div className="rightTabbing">
              <input
                type="search"
                placeholder="Search by name or email"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPagination((prev) => ({ ...prev, skip: 0 }));
                }}
                value={search}
              />
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Display Name</th>
              <th>Vendor</th>
              <th>Description</th>
              <th>Validity</th>
              <th>Data</th>
              {!getPathName && <th>Enable / Disable</th>}
              {getPathName && <th>Price</th>}
              
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={12} align="center" className={styles.loadingText}>
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading && plans.list.length === 0 && (
              <tr>
                <td colSpan={12} align="center">
                  No Plan Found
                </td>
              </tr>
            )}
            {!loading &&
              plans.list.map((item: any) => (
                <tr key={item._id}>
                  <td>{item?.displayName}</td>
                  <td>{item?.vendor === 1 ? "G+" : "Commbitz"}</td>
                  <td>{item?.description}</td>
                  <td>{item.duration} Days</td>
                  <td>{item.dataAmount}</td>
                  {!getPathName && <td><Toggle handleChange={() => updatePlanData(item)} value={item.isActive} /></td>}
                  {getPathName && <td className="cursor-pointer" onClick={() => togglePlanRetailPrice(item?._id)}>
                    { item?._id == priceToggle ?
                          <input
                            className={styles.input}
                            type="number"
                            value={68}
                            onKeyDown={(event) => {
                              if (event.keyCode === 13) setPriceToggle("");
                            }}
                          /> : 68
                    }
                  </td>}
                  </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={plans.count}
        />
      </div>
    </Layout>
  );
};

export default PartnerViewPlans;