import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { getNiyoApiSecretKey, niyGenerateApiSecretKey } from "../api";
import Layout from "../../../ui/layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getSandboxoApiSecretKey,
  sandboxGenerateApiSecretKey,
  sandboxLogin,
} from "../api/sanBoxApi";
import {
  NIYO_SWAGGER_URL,
  SANDBOX_SWAGGER_URL,
  TRAVELTHRU_SWAGGER_URL,
} from "../../../config";

const SecretKeyConfiguration = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const roles = window.localStorage.getItem("admin_roles");

  const getActiveTab = LOCAL_CONSTANTS.ROLES.GLOBALTIX !== Number(roles) ? 1 : 2

  const { partnerName, partnerWebsiteId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<Number>(getActiveTab);
  const [secretKeyShow, setSecretKeyShow] = useState<boolean>(false);
  const [sanboxTokan, setSanboxTokan] = useState<string>("");
  const [secertDetails, setSecertDetails] = useState<any>();
  
  const routAccess =
    (roles !== null && LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)) ||
    (roles !== null && LOCAL_CONSTANTS.ROLES.VFS_USER === Number(roles)) ||
    (roles !== null && LOCAL_CONSTANTS.ROLES.TRAVELTHRU_USER === Number(roles));
  const params =
    LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles) ? "niyo" : "travelthru";

  const getSecretKeyAPis = (sanboxTokan: string) => {
    activeTab === 1
      ? getNiyoApiSecretKey()
          .then((res) => {
            setSecertDetails({
              secretKey: res?.data?.data?.secretKey,
              apikey: res?.data?.data?.apiKey,
            });
          })
          .catch((res: any) => {
            toast.error(res?.data?.message || "Something went wrong!");
          })
      : getSandboxoApiSecretKey(sanboxTokan)
          .then((res) => {
            setSecertDetails({
              secretKey: res?.data?.data?.data?.secretKey,
              apikey: res?.data?.data?.data?.apiKey,
            });
          })
          .catch((res: any) => {
            toast.error(res?.data?.message || "Something went wrong!");
          });
  };
  useEffect(() => {
    sandboxLogin({
      email: "travelthru@gmail.com",
      password: "travelthru@gmail.com",
    })
      .then((res: any) => {
        setSanboxTokan(res?.data?.accessToken);
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || "Something went wrong!");
      });
  }, [activeTab]);

  useEffect(() => {
    if (sanboxTokan) getSecretKeyAPis(sanboxTokan);
  }, [sanboxTokan]);

  const generatSecretKey = (tabId: any) => {
    tabId == 1
      ? niyGenerateApiSecretKey()
          .then((res) => {
            setSecertDetails(res?.data?.data);
          })
          .catch((res: any) => {
            toast.error(res?.data?.message || "Something went wrong!");
          })
      : sandboxGenerateApiSecretKey(sanboxTokan)
          .then((res) => {
            setSecertDetails(res?.data?.data?.data);
          })
          .catch((res: any) => {
            toast.error(res?.data?.message || "Something went wrong!");
          });
  };

  const SWAGGERuRL =
    LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)
      ? NIYO_SWAGGER_URL
      : LOCAL_CONSTANTS.ROLES.TRAVELTHRU_USER === Number(roles)
      ? TRAVELTHRU_SWAGGER_URL
      : SANDBOX_SWAGGER_URL;
  return (
    <Layout title={`Secret keys details`}>
      <div className={commonStyles.tableData}>
        <div className={styles.mainTabbing}>
          <div className="leftTabbing">
            <div className={styles.topCms}>
              <ul>
                {LOCAL_CONSTANTS.ROLES.GLOBALTIX !== Number(roles) && (
                  <li
                    className={activeTab === 1 ? styles.active : ""}
                    onClick={() => {
                      setActiveTab(1);
                    }}
                  >
                    <p>Live secret keys</p>
                  </li>
                )}
                {LOCAL_CONSTANTS.ROLES.NIYO_USER !== Number(roles) && (
                  <li
                    className={activeTab === 2 ? styles.active : ""}
                    onClick={() => {
                      setActiveTab(2);
                    }}
                  >
                    <p>Sandbox Secret keys</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="rightTabbing"></div>
        </div>
        <div className="secretkey-main">
          <div className="main-form">
            <div className="form-group">
              <label htmlFor="">Secret key : </label>
              <button onClick={() => generatSecretKey(activeTab)}>
                Generat
              </button>
            </div>
            <div className="getSecretKey">
              <div className="secretKeys">
                <label htmlFor="">Secret key:</label>
                <div className="secretkeyNew">
                  {secretKeyShow
                    ? secertDetails?.secretKey
                    : "*******************************************"}
                </div>
                <button onClick={() => setSecretKeyShow(!secretKeyShow)}>
                  {secretKeyShow ? "Hide" : "Show"}
                </button>
              </div>
              <div className="apiKeys">
                <div className="secretkey">
                  <label htmlFor="">Api key:</label>
                  <span>{secertDetails?.apikey}</span>
                </div>
              </div>

              <div className="apiKeys">
                <div className="secretkey">
                  <label htmlFor="">Swagger Url:</label>
                  <a href={SWAGGERuRL} target="_blank">
                    Swagger Url
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SecretKeyConfiguration;
