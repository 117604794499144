import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import moment from "moment";
import { OrderTypes } from "../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import UpsellForm from "../../../ui/modals/upsellForm";
import { manuallySentMail } from "../api";
import { toast } from "react-toastify";

interface UserListProp {
  loading: boolean;
  orders: any;
  setRefreshData: any;
  refreshData: any
}

const OrderList = ({ loading, orders, setRefreshData, refreshData }: UserListProp) => {
  const isFree = orders?.list[0]?.isFree;
  const [show, setShow] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>("");
  const { partnerName, partnerWebsiteId } = useParams();

  const navigate = useNavigate();

  const roles = window.localStorage.getItem("admin_roles")
  const routAccess = (roles !== null && LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)) || (roles !== null && LOCAL_CONSTANTS.ROLES.VFS_USER === Number(roles))


  return (
    <>
      <div className={styles.total_count}>
        <label>Total Order</label>
        <span className={styles.order_count}>{orders?.count}</span>
      </div>
      <div className={styles.scrollTable}>
        <table>
          <thead>
            <tr>
              <th>#</th>
              {isFree &&
                <th>Name</th>
              }
              <th>Email</th>
              {isFree &&
                <th>Phone Number</th>
              }
              <th>{!isFree ? "Bundle Name" : "Travelling Country"}</th>
              <th>Order Date</th>
              <th>Order Placed</th>
              <th>Travelling Date</th>
              {isFree &&
                <>
                  <th>QR sent</th>
                  {LOCAL_CONSTANTS.ROLES.COMMBITZ_USER === Number(roles) &&
                    <th>Manual mail</th>
                  }

                </>
              }
              {!isFree && (
                <>
                  <th>Payment Status</th>
                  <th>Currency</th>
                  <th>Price</th>
                  <th>Quantity</th>
                </>
              )}
              {partnerName?.toLocaleLowerCase() !== LOCAL_CONSTANTS.PARTNERS.GLOBALTIX && <th>Action</th>}

            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={7} align="center" className={styles.loadingText}>
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading && orders.list.length === 0 && (
              <tr>
                <td colSpan={7} align="center">
                  Order not found
                </td>
              </tr>
            )}
            {!loading &&
              orders.list.map((item: any, index: number) => (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  {isFree &&
                    <td>{`${item.user?.fullName || LOCAL_CONSTANTS.na}`}</td>
                  }
                  <td>{`${item.user?.email}`}</td>
                  {isFree &&
                    <td>{!item.user?.countryCode ? LOCAL_CONSTANTS.na : item.user?.countryCode + " " + item.user?.phoneNumber}</td>
                  }
                  <td>{item.bundle?.name}</td>
                  <td>
                    {moment(item?.createdAt).format(LOCAL_CONSTANTS.dateFormat)}
                  </td>
                  <td>
                    {
                      LOCAL_CONSTANTS.paymentOrderStatus?.find(
                        (elt: OrderTypes.StatusEnums) =>
                          elt.value === item.orderStatus
                      )?.label
                    }
                  </td>
                  <td> {item.user?.travellingDate ? moment(item.user?.travellingDate).format(LOCAL_CONSTANTS.dateFormat) : LOCAL_CONSTANTS.na}</td>
                  {isFree &&
                    <>

                      <td> {(item?.freeEsimQrEmailSent || item?.orderStatus == 2) ? "Yes" : "Pending"}</td>
                      {LOCAL_CONSTANTS.ROLES.COMMBITZ_USER === Number(roles) &&
                        <td> {(item?.freeEsimQrEmailSent || item?.orderStatus == 2) ? <button disabled
                          className={styles.loyalityPointBtn}
                        > Success</button>
                          : <button
                            className={styles.loyalityPointBtn}
                            onClick={() => {

                              toast.promise(
                                manuallySentMail({ orderId: item._id }),
                                {
                                  pending: {
                                    render() {
                                      return "Trying to send email...";
                                    },
                                  },
                                  success: {
                                    render({ data }) {
                                      setRefreshData(!refreshData);
                                      return "Email sent successfully.";
                                    }
                                  },
                                  error: {
                                    render({ data }) {
                                      return "Something went Wrong."
                                    },
                                  },
                                });
                            }}
                          > Pending</button>}</td>
                      }
                    </>
                  }
                  {!isFree && (
                    <>
                      <td>
                        {
                          LOCAL_CONSTANTS.paymentOrderStatus?.find(
                            (elt: OrderTypes.StatusEnums) =>
                              elt.value === item.paymentStatus
                          )?.label
                        }
                      </td>
                      <td>{item.priceCurrency}</td>
                      <td>{item?.price?.toFixed(2)}</td>
                      <td>{item.quantity}</td>
                      {/* {item?.documents &&  */}
                    </>
                  )}
                  {partnerName?.toLocaleLowerCase() !== LOCAL_CONSTANTS.PARTNERS.GLOBALTIX &&

                    <td>
                      <button
                        className={styles.loyalityPointBtn}
                        onClick={() => navigate(`/document/${item._id}`)}
                      >
                        View Documents
                      </button>
                      {isFree && (
                        <>
                          {!routAccess && (
                            <button
                              className={styles.loyalityPointBtn}
                              onClick={() => {
                                setOrderId(item._id)
                                setShow(true)
                              }
                              }
                            >
                              Upsell Form
                            </button>

                          )}
                          <button
                            className={styles.loyalityPointBtn}
                            onClick={() => navigate(`/partners/${partnerName}/${partnerWebsiteId}/upsell-records?orderId=${item._id}`)}
                          >
                            Upsell Records
                          </button>
                        </>)}
                    </td>
                  }

                  {/* } */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <UpsellForm show={show} setShow={setShow} orderId={orderId} />
    </>
  );
};

export default OrderList;
